import { Flex, Button, Box } from '@node-real/uikit';
import styled from '@emotion/styled';
import { BackIcon, ExternalLinkIcon } from '@node-real/icons';
import { useRouter } from 'next/router';

import { PolyhedraIcon } from './svgIcon/PolyhedraIcon';
import { reportEvent, GA_MAP } from '../utils/ga';

export const ThirdPartyBridgeTabs = () => {
  const router = useRouter() as any;

  return (
    <Container>
      <Flex
        alignItems={'center'}
        position={'absolute'}
        top={24}
        left={24}
        color={'readable.secondary'}
        _hover={{ color: 'scene.primary.normal' }}
      >
        <BackIcon height={20} width={20} />
        <BackButton
          onClick={() => {
            router.push('/deposit');
          }}
        >
          Back
        </BackButton>
      </Flex>

      <Flex flexDirection={'column'} gap={12} gridGap={12} width={'100%'}>
        <StyledButton
          onClick={() => {
            reportEvent({ name: GA_MAP.thirdPartyClick, data: { name: 'third party click' } });
            if (window) {
              window.open(
                `https://zkbridge.com/nft?contractAddress=0x9e8C1e7B35f646A606644a5532C6103C647938cf&tokenId=11&chainId=56`,
              );
            }
          }}
        >
          <Flex
            flex={1}
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Flex flexDirection={'row'} gap={8} gridGap={8}>
              <PolyhedraIcon width={40} height={40} />
              <Box textAlign={'left'}>
                <Title>Polyhedra</Title>
                <SubTitle>Trustless NFT Cross-chain Bridge with zkSNARKs.</SubTitle>
              </Box>
            </Flex>
            <ExternalLinkIcon width={16} height={16} />
          </Flex>
        </StyledButton>
        <StyledButton
          onClick={() => {
            reportEvent({ name: GA_MAP.thirdPartyClick, data: { name: 'third party click' } });
            if (window) {
              window.open(
                `https://zkbridge.com/nft?contractAddress=0x9e8C1e7B35f646A606644a5532C6103C647938cf&tokenId=11&chainId=56`,
              );
            }
          }}
        >
          <Flex
            flex={1}
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Flex flexDirection={'row'} gap={8} gridGap={8}>
              <PolyhedraIcon width={40} height={40} />
              <Box textAlign={'left'}>
                <Title>Polyhedra</Title>
                <SubTitle>Trustless NFT Cross-chain Bridge with zkSNARKs.</SubTitle>
              </Box>
            </Flex>
            <ExternalLinkIcon width={16} height={16} />
          </Flex>
        </StyledButton>
      </Flex>
      <BottomText>
        These are independent service providers that BNB Chain is linking to for your convenience -
        BNB Chain has no responsibility for their operation.
      </BottomText>
    </Container>
  );
};

const Container = styled(Flex)`
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  margin-bottom: 2px;
  color: ${(props: any) => props.theme.colors.readable.normal};
`;

const SubTitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: ${(props: any) => props.theme.colors.readable.secondary};
`;

const StyledButton = styled(Button)`
  padding: 16px;
  width: 100%;
  height: 72px;
  background: ${(props: any) => props.theme.colors.bg.status};
  color: ${(props: any) => props.theme.colors.readable.secondary};
  border: 1px solid ${(props: any) => props.theme.colors.bg.status};
  &:hover {
    color: ${(props: any) => props.theme.colors.scene.primary.normal};
    border: 1px solid ${(props: any) => props.theme.colors.scene.primary.normal};
    background: ${(props: any) => props.theme.colors.scene.primary.primaryOpacity};
  }
`;

const BottomText = styled.div`
  margin-top: 24px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${(props: any) => props.theme.colors.readable.disabled};
  text-align: center;
`;

const BackButton = styled(Button)`
  padding: 0;
  font-size: 16px;
  font-weight: 500;
  min-width: 0;
  width: 38px;
  height: 20px;
  background: ${(props: any) => props.theme.colors.bg.card};
  color: ${(props: any) => props.theme.colors.readable.secondary};
  &:hover {
    background: ${(props: any) => props.theme.colors.bg.card};
    color: ${(props: any) => props.theme.colors.scene.primary.normal};
  }
`;
