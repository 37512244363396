import { useState, useCallback } from 'react';
import { MobileTab, useResponsive } from '@op-bridge/bridge-core';
import { useAccount } from 'wagmi';

import { Wrapper, Title, SubTitle, PageWrapper } from './withdraw';
import { useGetChainProviders } from '../hooks';
import Deposit from '../components/Deposit';
import { Container } from '../components/style';
import { ThirdPartyBridgeTabs } from '../components/ThirdPartyBridgeTabs';
import { OnGoingWithdraw } from '../components/ongoing/OnGoingWithdraw';

export default function Index() {
  const { l1Chain, l2Chain } = useGetChainProviders();
  const { isConnected } = useAccount();
  const [tokenType, setTokenType] = useState('BNB');
  const { isMobile } = useResponsive();

  const handleTokenType = useCallback((type: string) => {
    setTokenType(type);
  }, []);

  const isMultiThirdParty = false;

  return (
    <PageWrapper>
      {isMobile && <MobileTab />}
      {isConnected && <OnGoingWithdraw />}
      <Container>
        <Wrapper>
          <Title>{isMultiThirdParty ? 'Third Party Bridge' : 'Deposit'}</Title>
          {!isMobile && (
            <SubTitle>
              {isMultiThirdParty
                ? 'More tokens and faster in the third-party bridge.'
                : `Transfer ${tokenType} from ${
                    l1Chain && l1Chain?.length > 0 && l1Chain[0].name
                  } to your${' '}
            ${l2Chain && l2Chain?.length > 0 && l2Chain[0].name} account.`}
            </SubTitle>
          )}
          {isMultiThirdParty ? (
            <ThirdPartyBridgeTabs />
          ) : (
            <Deposit handleTypeChange={handleTokenType} tokenType={tokenType} />
          )}
        </Wrapper>
      </Container>
    </PageWrapper>
  );
}
